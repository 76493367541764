import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import speechify from "../../Assets/Projects/speechify.jpg";  // Add appropriate image paths
import tixtales from "../../Assets/Projects/tixtales.jpg";
import trackly from "../../Assets/Projects/trackly.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={speechify}
              isBlog={false}
              title="Speechify"
              description="An Azure Functions and API-based system to process images, extract text, generate audio files, and provide an interface for uploading files and checking processing status."
              ghLink="https://github.com/IamEnoch/Speechify"  // Update with actual GitHub link
              demoLink="https://speechify-demo.com"  // Update with actual demo link
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tixtales}
              isBlog={false}
              title="Tixtales"
              description="A ticket booking application that allows users to book tickets and track upcoming events."
              ghLink="https://github.com/IamEnoch/TixTales"  // Update with actual GitHub link
              demoLink="https://tixtales-demo.com"  // Update with actual demo link
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={trackly}
              isBlog={false}
              title="Trackly"
              description="An application that aids in asset management and tracking of assets within an organization."
              ghLink="https://github.com/IamEnoch/Trackly"  // Update with actual GitHub link
              demoLink="https://trackly-demo.com"  // Update with actual demo link
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
