import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Enoch Mwangi</span> from{" "}
            <span className="purple">Kenya.</span>
            <br />
            I am a software developer with a focus on backend development and mobile apps.
            <br />
            I have experience working with technologies like <b className="purple">C#, .NET, Dart, and Flutter</b>.
            <br />
            <br />
            Apart from coding, here are a few activities I enjoy:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Football
            </li>
            <li className="about-activity">
              <ImPointRight /> Watching Movies
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring New Technologies
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build scalable solutions that make an impact!"{" "}
          </p>
          <footer className="blockquote-footer">Enoch Mwangi</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
